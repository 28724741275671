import '../App.css';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { TileLayer, Marker, CircleMarker, Popup, FeatureGroup, ImageOverlay, GeoJSON, useMap } from 'react-leaflet';
import Leaflet from "leaflet";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend, LineController } from "chart.js";
import { Bar } from "react-chartjs-2";
import card_list from '../list/card_list.json';
import chroma from "chroma-js";
import Papa from "papaparse";

const Layer = ({ selectedLayer }) => {

  // ラスターレイヤの処理
  const rasters = selectedLayer
    .filter(slayer => slayer.type === "raster") // selectedLayer の中から "raster" タイプのみ抽出
    .map(slayer => (
      <TileLayer
        key={slayer.id}
        attribution={slayer.attribution || ""} // 必要に応じてデフォルト値を設定
        url={slayer.url || ""}
        className={slayer.blend ? "multiply-blend" : ""}
        opacity={slayer.permeability !== undefined ? 1 - slayer.permeability : 1} // 透過度に基づく不透明度を設定
      />
    ));

  const CSVOverlay = ({ url, attribution }) => {
    const [imageDataUrl, setImageDataUrl] = useState(null);
    const [bounds, setBounds] = useState(null);

    useEffect(() => {
      const loadCSV = async (csvUrl) => {
        try {
          Papa.parse(csvUrl, {
            download: true,
            complete: (result) => {
              const data = result.data.map((row) =>
                row.map((value) => (value.trim() === "" ? null : Number(value)))
              );

              if (data.length === 0) return;

              const height = data.length;
              const width = data[0].length;

              // メルカトル投影用の定数
              const R = 6378137; // 地球の半径（メートル）
              const latMin = -85.05112878; // メルカトル図法の限界
              const latMax = 85.05112878;
              const lonMin = -180;
              const lonMax = 180;

              // 正確なメルカトル変換関数
              const latToY = (lat) => {
                const latRad = (lat * Math.PI) / 180;
                return Math.log(Math.tan(Math.PI / 4 + latRad / 2)) * R;
              };

              const yToLat = (y) => {
                return (2 * Math.atan(Math.exp(y / R)) - Math.PI / 2) * 180 / Math.PI;
              };

              // データの緯度経度範囲を計算（0.25度間隔と仮定）
              const dataLatStep = (latMax - latMin) / (height - 1);
              const dataLonStep = (lonMax - lonMin) / (width - 1);

              // キャンバスサイズを適切に設定（高解像度化）
              const canvasWidth = width * 4;  // 解像度を4倍に
              const canvasHeight = height * 4;

              const canvas = document.createElement("canvas");
              canvas.width = canvasWidth;
              canvas.height = canvasHeight;
              const ctx = canvas.getContext("2d");
              if (!ctx) return;

              const imageData = ctx.createImageData(canvasWidth, canvasHeight);

              // 温度 -> 色マッピング
              const getColor = (temp) => {
                if (temp === null || isNaN(temp)) return [0, 0, 0, 0];

                const minTemp = 0;
                const maxTemp = 300;
                const ratio = Math.max(0, Math.min(1, (temp - minTemp) / (maxTemp - minTemp)));
                const r = Math.round(255 * ratio);
                const b = Math.round(255 * (1 - ratio));
                return [r, 0, b, 255];
              };

              // ピクセルを初期化（透明に）
              for (let i = 0; i < imageData.data.length; i += 4) {
                imageData.data[i + 3] = 0;
              }

              // メルカトルに合わせたマッピングとバイリニア補間
              for (let canvasY = 0; canvasY < canvasHeight; canvasY++) {
                // キャンバスY座標から緯度を計算
                const mercY = (canvasY / canvasHeight) * (latToY(latMin) - latToY(latMax)) + latToY(latMax);
                const lat = yToLat(mercY);

                for (let canvasX = 0; canvasX < canvasWidth; canvasX++) {
                  // キャンバスX座標から経度を計算
                  const lon = (canvasX / canvasWidth) * (lonMax - lonMin) + lonMin;

                  // 緯度経度からCSVデータのインデックスを計算（小数点以下も保持）
                  const dataX = (lon - lonMin) / dataLonStep;
                  const dataY = (latMax - lat) / dataLatStep;

                  // 補間用の整数インデックスと小数部分
                  const x0 = Math.floor(dataX);
                  const y0 = Math.floor(dataY);
                  const x1 = Math.min(x0 + 1, width - 1);
                  const y1 = Math.min(y0 + 1, height - 1);
                  const xFrac = dataX - x0;
                  const yFrac = dataY - y0;

                  // 範囲外チェック
                  if (x0 < 0 || x0 >= width || y0 < 0 || y0 >= height) {
                    continue;
                  }

                  // バイリニア補間で温度を計算
                  let temp = null;
                  const v00 = data[y0][x0];
                  const v01 = data[y0][x1];
                  const v10 = data[y1][x0];
                  const v11 = data[y1][x1];

                  if (v00 !== null && v01 !== null && v10 !== null && v11 !== null) {
                    // 全ての点が有効な場合のみ補間
                    const top = v00 * (1 - xFrac) + v01 * xFrac;
                    const bottom = v10 * (1 - xFrac) + v11 * xFrac;
                    temp = top * (1 - yFrac) + bottom * yFrac;
                  } else if (v00 !== null) {
                    // 少なくとも一点が有効ならその値を使用
                    temp = v00;
                  } else if (v01 !== null) {
                    temp = v01;
                  } else if (v10 !== null) {
                    temp = v10;
                  } else if (v11 !== null) {
                    temp = v11;
                  }

                  // ピクセルに色を設定
                  const index = (canvasY * canvasWidth + canvasX) * 4;
                  const [r, g, b, a] = getColor(temp);
                  imageData.data[index] = r;
                  imageData.data[index + 1] = g;
                  imageData.data[index + 2] = b;
                  imageData.data[index + 3] = a;
                }
              }

              ctx.putImageData(imageData, 0, 0);
              setImageDataUrl(canvas.toDataURL());

              // 正確な境界を設定
              setBounds([
                [latMax, lonMin], // 左上 (北西)
                [latMin, lonMax]  // 右下 (南東)
              ]);
            },
          });
        } catch (error) {
          console.error("CSV 読み込みエラー:", error);
        }
      };

      if (url) {
        loadCSV(url);
      }
    }, [url]);

    return imageDataUrl && bounds ? (
      <ImageOverlay bounds={bounds} url={imageDataUrl} opacity={0.8} attribution={attribution || ""} />
    ) : null;
  };

  // CSVレイヤの処理
  const csv = selectedLayer
    .filter(slayer => slayer.type === "csv") // selectedLayer の中から "csv" タイプのみ抽出
    .map(slayer => (
      <CSVOverlay key={slayer.id} url={slayer.url} attribution={slayer.attribution || ""} />
    ));

  const CSVOverlay2 = ({ url, attribution }) => {
    const [imageDataUrl, setImageDataUrl] = useState(null);
    const [bounds, setBounds] = useState(null);

    useEffect(() => {
      const loadCSV = async (csvUrl) => {
        try {
          Papa.parse(csvUrl, {
            download: true,
            complete: (result) => {
              const data = result.data.map((row) =>
                row.map((value) => (value.trim() === "" ? null : Number(value)))
              );

              if (data.length === 0) return;

              const width = data[0].length;
              const height = data.length;

              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");
              if (!ctx) return;

              canvas.width = width;
              canvas.height = height;

              const imageData = ctx.createImageData(width, height);

              // 温度 -> 色マッピング
              const getColor = (temp) => {
                const minTemp = 0;
                const maxTemp = 300;
                const ratio = (temp - minTemp) / (maxTemp - minTemp);
                const r = Math.round(255 * ratio);
                const b = Math.round(255 * (1 - ratio));
                return [r, 0, b, 255];
              };

              // メルカトル変換
              const mercatorY = (lat) =>
                Math.log(Math.tan(Math.PI / 4 + (lat * Math.PI) / 360));

              const latMin = -85; // メルカトル図法では ±85度付近が限界
              const latMax = 85;
              const lonMin = -180;
              const lonMax = 180;

              const latStep = (latMax - latMin) / height;
              const lonStep = (lonMax - lonMin) / width;

              // ピクセル描画 (メルカトル変換適用)
              for (let y = 0; y < height; y++) {
                const lat = latMax - y * latStep;
                const mercatorLat = mercatorY(lat);
                for (let x = 0; x < width; x++) {
                  const lon = lonMin + x * lonStep;
                  const adjustedLon = lon;
                  const temp = data[y][x];

                  const mappedX = Math.round(((adjustedLon - lonMin) / (lonMax - lonMin)) * width);
                  const mappedY = Math.round(((mercatorY(lat) - mercatorY(latMax)) / (mercatorY(latMin) - mercatorY(latMax))) * height);

                  if (mappedX >= 0 && mappedX < width && mappedY >= 0 && mappedY < height) {
                    const index = (mappedY * width + mappedX) * 4;

                    if (isNaN(temp) || temp === null) {
                      imageData.data[index + 3] = 0; // 透明
                    } else {
                      const [r, g, b, a] = getColor(temp);
                      imageData.data[index] = r;
                      imageData.data[index + 1] = g;
                      imageData.data[index + 2] = b;
                      imageData.data[index + 3] = a;
                    }
                  }
                }
              }

              ctx.putImageData(imageData, 0, 0);
              setImageDataUrl(canvas.toDataURL()); // Data URL をセット

              setBounds([
                [latMax, lonMin], // 左上 (北西)
                [latMin, lonMax], // 右下 (南東)
              ]);
            },
          });
        } catch (error) {
          console.error("CSV 読み込みエラー:", error);
        }
      };

      if (url) {
        loadCSV(url);
      }
    }, [url]);

    return imageDataUrl && bounds ? (
      <ImageOverlay bounds={bounds} url={imageDataUrl} opacity={0.8} attribution={attribution || ""} />
    ) : null;
  };

  // CSVレイヤの処理
  const csv2 = selectedLayer
    .filter(slayer => slayer.type === "csv2") // selectedLayer の中から "csv" タイプのみ抽出
    .map(slayer => (
      <CSVOverlay2 key={slayer.id} url={slayer.url} attribution={slayer.attribution || ""} />
    ));

  let climates = null; // climates を初期化
  let manhole_distributions = null; // manhole_distributions を初期化

  // climates の処理
  const [climateData, setClimateData] = useState(null);

  useEffect(() => {
    const matchingLayers = selectedLayer.filter(layer =>
      ["climate_world", "climate_japan", "climate_world_koppen", "climate_japan_koppen"].includes(layer.id)
    );

    if (matchingLayers.length > 0) {
      const loadGeoJSONs = async () => {
        try {
          const responses = await Promise.all(
            matchingLayers.map(layer => fetch(layer.url))
          );

          const data = await Promise.all(
            responses.map(response => {
              if (!response.ok) {
                throw new Error(`Failed to fetch GeoJSON data from ${response.url}`);
              }
              return response.json();
            })
          );

          // 複数のGeoJSONデータをまとめてセット
          setClimateData(data);
        } catch (error) {
          console.error("Error loading GeoJSON:", error);
        }
      };

      loadGeoJSONs();
    }
  }, [selectedLayer]);

  // Chart.js の登録は条件外で行う
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LineController
  );

  // 雨温図のデータ作成関数とオプション設定は変わりません
  const createChartData = (temperature, precipitation) => ({
    labels: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
    datasets: [
      {
        type: "line",
        label: "月平均気温(°C)",
        data: temperature,
        borderColor: "#ff6347",
        backgroundColor: "#ff6347",
        yAxisID: "y1",
      },
      {
        type: "bar",
        label: "月降水量(mm)",
        data: precipitation,
        backgroundColor: "#1e90ff",
        yAxisID: "y2",
      },
    ],
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltip: {
      bodyFont: {
        family: 'BIZ UDPGothic'
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            family: 'BIZ UDPGothic'
          },
        },
        position: "top",
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            family: 'BIZ UDPGothic'
          },
        }
      },
      y1: {
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: "月平均気温(℃)",
          font: {
            family: 'BIZ UDPGothic'
          },
        },
        min: -30,
        max: 40,
        ticks: {
          font: {
            family: 'BIZ UDPGothic'
          },
        },
      },
      y2: {
        type: "linear",
        position: "right",
        title: {
          display: true,
          text: "月降水量(mm)",
          font: {
            family: 'BIZ UDPGothic'
          },
        },
        grid: {
          drawOnChartArea: false,
        },
        min: 0,
        max: 800,
        ticks: {
          font: {
            family: 'BIZ UDPGothic'
          },
        },
      },
    },
  };


  let type = "" //乾燥季節型
  let arid_boundary = "" //乾燥限界値
  //ケッペンの気候区判定
  const koppen = (temperature, precipitation, latitude) => {

    const sum = function (arr) { //合計を求める関数
      return arr.reduce(function (prev, current, i, arr) {
        return prev + current;
      });
    };

    if (temperature.every(element => element === null)) {
      return "気温データ欠損のため，気候区判定不可"
    }
    else if (precipitation.every(element => element === null)) {
      return "降水量データ欠損のため，気候区判定不可"
    }

    const maxTemp = Math.max.apply(null, temperature); //最暖月平均気温
    const minTemp = Math.min.apply(null, temperature); //最寒月平均気温
    const aveTemp = sum(temperature) / 12; //年平均気温

    const sumPrec = sum(precipitation) //年降水量
    const maxPrec = Math.max.apply(null, precipitation); //最多雨月降水量
    const minPrec = Math.min.apply(null, precipitation); //最少雨月降水量
    const minPrecM = precipitation.indexOf(minPrec) + 1; //最多雨月

    type = "f" //乾燥季節型
    arid_boundary = 0 //乾燥限界値

    //乾燥季節型の判定
    if (latitude >= 0 && (minPrecM <= 3 || minPrecM >= 10) && minPrec * 10 < maxPrec) { //北半球で，最少雨月が冬にあり，最少雨月降水量の10倍が最多雨月降水量を下回る
      type = "w" //冬季乾燥型
      arid_boundary = 20 * (aveTemp + 14)
    }
    else if (latitude < 0 && (minPrecM >= 4 && minPrecM <= 10) && minPrec * 10 < maxPrec) { //南半球で，最少雨月が冬にあり，最少雨月降水量の10倍が最多雨月降水量を下回る
      type = "w" //冬季乾燥型
      arid_boundary = 20 * (aveTemp + 14)
    }
    else if (latitude >= 0 && (minPrecM >= 4 && minPrecM <= 10) && minPrec * 3 < maxPrec) { //北半球で，最少雨月が夏にあり，最少雨月降水量の3倍が最多雨月降水量を下回る
      type = "s" //夏季乾燥型
      arid_boundary = 20 * (aveTemp)
    }
    else if (latitude < 0 && (minPrecM <= 3 || minPrecM >= 10) && minPrec * 3 < maxPrec) { //南半球で，最少雨月が夏にあり，最少雨月降水量の3倍が最多雨月降水量を下回る
      type = "s" //夏季乾燥型
      arid_boundary = 20 * (aveTemp)
    }
    else {
      type = "f" //年中湿潤型
      arid_boundary = 20 * (aveTemp + 7)
    };


    if (maxTemp < 10) { //寒帯である(最暖月平均気温が10℃未満)
      if (maxTemp < 0) { //最暖月平均気温が0℃未満
        return "氷雪気候(EF)"
      }
      else {
        return "ツンドラ気候(ET)"
      }
    }
    else if (sumPrec < arid_boundary) { //乾燥帯である(年降水量が乾燥限界値を下回る)
      if (sumPrec >= 0.5 * arid_boundary) { //年降水量が乾燥限界値の1/2以上である
        return "ステップ気候(BS)"
      }
      else {
        return "砂漠気候(BW)"
      }
    }
    else if (minTemp >= 18) { //熱帯である(最寒月平均気温が18℃以上)
      if (minPrec >= 60) { //最少雨月降水量が60mm以上
        return "熱帯雨林気候(Af)"
      }
      else if (100 - 0.04 * sumPrec <= minPrec) {
        return "弱い乾季のある熱帯雨林気候(Am)"
      }
      else {
        return "サバナ気候(Aw)"
      }
    }
    else if (minTemp >= -3) { //温帯である(最寒月平均気温が-3℃以上18℃未満)
      if (type === "s" && minPrec < 30) {//夏季乾燥型かつ最少雨月降水量が30mm未満
        return "地中海性気候(Cs)"
      }
      else if (type === "w") {
        return "温暖冬季少雨気候(Cw)"
      }
      else { //年中湿潤型の判定(Cfa，Cfb)
        if (maxTemp >= 22) {//最暖月平均気温が22℃以上
          return "温暖湿潤気候(Cfa)"
        }
        else {
          return "西岸海洋性気候(Cfb)"
        }
      }
    }
    else if (minTemp < -3) { //亜寒帯である(最寒月平均気温が-3℃未満)
      if (type === "w") {
        return "亜寒帯冬季少雨気候(Dw)"
      }
      else {
        return "亜寒帯湿潤気候(Df)"
      }
    }
    else {
      return "気候区判定不可"
    }
  };

  // 地図上のマーカーをレンダリング
  if (
    selectedLayer.some(layer =>
      ["climate_world", "climate_japan", "climate_world_koppen", "climate_japan_koppen"].includes(layer.id)
    ) && climateData
  ) {
    climates = climateData.flatMap((geoData, dataIndex) => {
      if (!geoData.features) {
        console.error(`GeoJSON data at index ${dataIndex} does not contain features.`);
        return [];
      }

      return geoData.features.map((feature, index) => {
        const chartData = createChartData(
          feature.properties.temperature,
          feature.properties.precipitation
        );
        const coordinates = feature.geometry.coordinates;
        const adjustedLongitude = coordinates[0] >= -25 ? coordinates[0] : coordinates[0] + 360;

        return (
          <FeatureGroup key={`data-${dataIndex}-feature-${index}`} attribution="理科年表">
            <Popup>
              <p>{feature.properties.city} ({feature.properties.country || feature.properties.prefecture})</p>
              <div style={{ width: "250px", height: "250px" }}>
                <Bar data={chartData} options={options} />
              </div>
              <p>ケッペンの気候区分：{koppen(feature.properties.temperature, feature.properties.precipitation, feature.properties.latitude)}</p>
            </Popup>
            <CircleMarker
              center={[coordinates[1], adjustedLongitude]}
              fillColor="#ff7800"
              radius={5}
              color={"#000"}
              weight={0.5}
              opacity={1}
              fillOpacity={0.8}
            />
          </FeatureGroup>
        );
      });
    });
  }

  // latlon の処理
  const LatLon = ({ url, attribution }) => {
    const [latlonData, setLatlonData] = useState(null);

    useEffect(() => {
      const loadGeoJSON = async () => {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Failed to fetch GeoJSON data");
          }
          const data = await response.json();
          setLatlonData(data);
        } catch (error) {
          console.error("Error loading GeoJSON:", error);
        }
      };

      loadGeoJSON();
    }, [url]);

    if (!latlonData) return null; // データがまだ読み込まれていない場合は何も表示しない

    // 名前に基づく色を設定する関数
    const getColorByName = (name) => {
      switch (name) {
        case "赤道":
          return "#ff6347"; // 赤
        case "本初子午線":
          return "#4169e1"; // 青
        case "経度180度":
          return "#4169e1"; // 青
        default:
          return "#808080"; // 灰色
      }
    };

    const onEachFeature = (feature, layer) => {
      if (feature.properties && feature.properties.name) {
        layer.bindPopup(feature.properties.name); // 名前があればポップアップを設定
      }
    };

    // スタイル設定
    const style = (feature) => {
      const name = feature.properties?.name || "";
      return {
        color: getColorByName(name), // 境界線の色
        weight: 2, // 線の太さ
      };
    };

    return <GeoJSON data={latlonData} onEachFeature={onEachFeature} style={style} attribution={attribution} />;
  };

  const latlonLayers = selectedLayer
    .filter(slayer => slayer.type === "latlon") // selectedLayer の中から "latlon" タイプのみ抽出
    .map(slayer => (
      <LatLon key={slayer.id} url={slayer.url} attribution={slayer.attribution} />
    ));

  // atmospheric の処理
  const Atmospheric = ({ url, attribution }) => {
    const [atmosphericData, setAtmosphericData] = useState(null);

    useEffect(() => {
      const loadGeoJSON = async () => {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Failed to fetch GeoJSON data");
          }
          const data = await response.json();
          setAtmosphericData(data);
        } catch (error) {
          console.error("Error loading GeoJSON:", error);
        }
      };

      loadGeoJSON();
    }, [url]);

    if (!atmosphericData) return null; // データがまだ読み込まれていない場合は何も表示しない

    // 名前に基づく色を設定する関数
    const getColorByName = (name) => {
      switch (name) {
        case "赤道低圧帯（熱帯収束帯）":
          return "#ff6347"; // 赤
        case "亜熱帯高圧帯（中緯度高圧帯）":
          return "#4169e1"; // 青
        case "亜寒帯低圧帯":
          return "#ff6347"; // 赤
        default:
          return "#808080"; // 灰色
      }
    };

    // 名前に基づく説明を設定する関数
    const getDescByName = (name) => {
      switch (name) {
        case "赤道低圧帯（熱帯収束帯）":
          return "赤道付近にあり，貿易風の収束によって形成される。上空5km前後のところに赤道西風が吹く。";
        case "亜熱帯高圧帯（中緯度高圧帯）":
          return "回帰線付近に中心がある高圧帯である。風は弱いが，ここから高緯度側に偏西風が，低緯度側には貿易風が吹き出す。";
        case "亜寒帯低圧帯":
          return "極偏東風と偏西風の収束により，地上付近でジェット気流と対応して形成される。温帯低気圧が発生する。";
        default:
          return "";
      }
    };

    const onEachFeature = (feature, layer) => {
      if (feature.properties && feature.properties.name) {
        layer.bindPopup(feature.properties.name + "<br/>" + getDescByName(feature.properties.name)); // 名前があればポップアップを設定
      }
    };

    // スタイル設定
    const style = (feature) => {
      const name = feature.properties?.name || "";
      return {
        color: getColorByName(name), // 境界線の色
        weight: 2, // 線の太さ
        fillColor: getColorByName(name), // 塗りつぶしの色
        fillOpacity: 0.5, // 塗りつぶしの透明度
      };
    };

    return <GeoJSON data={atmosphericData} onEachFeature={onEachFeature} style={style} attribution={attribution} />;
  };

  const atmosphericLayers = selectedLayer
    .filter(slayer => slayer.type === "atmospheric") // selectedLayer の中から "atmospheric" タイプのみ抽出
    .map(slayer => (
      <Atmospheric key={slayer.id} url={slayer.url} attribution={slayer.attribution} />
    ));


  // current の処理
  const Current = ({ url, attribution }) => {
    const [currentData, setCurrentData] = useState(null);

    useEffect(() => {
      const loadGeoJSON = async () => {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Failed to fetch GeoJSON data");
          }
          const data = await response.json();
          setCurrentData(data);
        } catch (error) {
          console.error("Error loading GeoJSON:", error);
        }
      };

      loadGeoJSON();
    }, [url]);

    if (!currentData) return null; // データがまだ読み込まれていない場合は何も表示しない

    // 名前に基づく色を設定する関数
    const getColorByType = (type) => {
      switch (type) {
        case "warm":
          return "#ff6347"; // 赤
        case "cold":
          return "#4169e1"; // 青
        default:
          return "#808080"; // 灰色
      }
    };

    const onEachFeature = (feature, layer) => {
      if (feature.properties && feature.properties.name && feature.properties.type === "warm") { // 暖流ならば名前を設定
        layer.bindPopup(feature.properties.name + "（暖流）");
      }
      else if (feature.properties && feature.properties.name && feature.properties.type === "cold") { // 寒流ならば名前を設定
        layer.bindPopup(feature.properties.name + "（寒流）");
      }
    };

    // スタイル設定
    const style = (feature) => {
      const type = feature.properties?.type || "";
      return {
        color: getColorByType(type), // 境界線の色
        weight: 5, // 線の太さ
      };
    };

    return <GeoJSON data={currentData} onEachFeature={onEachFeature} style={style} attribution={attribution} />;
  };

  const currentLayers = selectedLayer
    .filter(slayer => slayer.type === "current") // selectedLayer の中から "current" タイプのみ抽出
    .map(slayer => (
      <Current key={slayer.id} url={slayer.url} attribution={slayer.attribution} />
    ));

  // manhole_distributions の処理
  if (card_list && selectedLayer.some(layer => layer.type === "manhole_distribution")) {
    manhole_distributions = card_list.map((card, cardIndex) => {
      if (card.locations.length) {

        const manholeMarker = () => {
          return Leaflet.icon({
            iconUrl: "/manhole/icon.png",
            iconSize: [25, 25],
            className: "marker",
          });
        };

        const markers = card.locations.map((location, locIndex) => (
          <Marker
            key={`${cardIndex}-${locIndex}`} // ユニークなキー
            position={[
              Number(location.latitude),
              Number(location.longitude)
            ]}
            icon={manholeMarker()}
          >
            <Popup>
              <p style={{ "fontWeight": "bold" }}>{card.card_name}<Badge bg="secondary">{"第" + card.version + "弾"}</Badge></p>
              <p>{location.name}</p>
              <p>{card.open_time}</p>
              <p>{card.stock}</p>
              <img src={"/manhole/" + card.card_image} className="manhole_card" alt={card.card_image} />
            </Popup>
          </Marker>
        ));

        return (
          <FeatureGroup key={cardIndex} attribution="<a href='https://www.gk-p.jp/activity/mc/' target='_blank'>下水道広報プラットフォーム</a>">
            {markers}
          </FeatureGroup>
        );
      }
      return null; // 条件を満たさない場合は null を返す
    }).filter(item => item !== null); // 無効な要素を除外
  }

  return (
    <>
      {rasters}
      {csv}
      {csv2}
      {atmosphericLayers}
      {climates}
      {currentLayers}
      {latlonLayers}
      {manhole_distributions}
    </>
  );
};

export default Layer;