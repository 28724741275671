import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Navbar, NavDropdown, DropdownDivider, Modal, Button, Toast, ToastContainer } from 'react-bootstrap';
import '../App.css';
import { Link } from 'react-router-dom';
import html2canvas from 'html2canvas';

const Header = ({ mode, setMode }) => {

    // モードを選択する関数
    const handleSelect = (selectedMode) => {
        if (mode !== selectedMode) {
            setMode(selectedMode);
        }
    };

    //スクリーンショット系統
    const [showModal, setShowModal] = useState(false);
    const [screenshotUrl, setScreenshotUrl] = useState(null);

    const onScreenshotClick = () => {
        const mapElement = document.getElementById('map'); // mapview.js の div ID を指定
        if (mapElement) {
            html2canvas(mapElement).then((canvas) => {
                const imageUrl = canvas.toDataURL(); // スクリーンショット画像のデータURL
                setScreenshotUrl(imageUrl);
                setShowModal(true); // モーダルを表示
            }).catch((error) => {
                console.error('スクリーンショットの取得に失敗しました:', error);
            });
        } else {
            console.error('地図要素が見つかりません');
        }
    };

    const handleCloseModal = () => setShowModal(false);

    const [showToast, setShowToast] = useState(false);

    const handleShareClick = () => {
      const shareUrl = window.location.href; // 現在のページURLを取得
  
      navigator.clipboard
        .writeText(shareUrl)
        .then(() => {
          setShowToast(true); // トーストを表示
        })
        .catch((err) => {
          console.error('リンクのコピーに失敗しました:', err);
        });
    };

    return (
        <>
            <Navbar className="header_style" variant="dark" fixed="top" expand="lg">
                <Navbar.Brand className="header_title">教育用WebGIS</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/">初期表示</Nav.Link>
                        <Nav.Link onClick={() => onScreenshotClick()}>スクリーンショット</Nav.Link>
                        <Nav.Link onClick={() => handleShareClick()}>共有</Nav.Link>
                        <Nav.Link href="/how_to_use.pdf">使い方</Nav.Link>
                        <Nav.Link href="https://forms.gle/4P5vTYJ7wpGgu8Yt9">お問い合わせ</Nav.Link>
                        <NavDropdown
                            id="mode_select"
                            title="モード選択"
                            menuVariant="light"
                            align="end"
                        >
                            <NavDropdown.ItemText>
                                {mode === 'japan' ? '日本モードを選択中' : mode === 'world' ? '世界モードを選択中' : '不明なモードを選択中'}
                            </NavDropdown.ItemText>
                            <DropdownDivider />
                            {mode === 'japan' ? (
                                <NavDropdown.Item
                                    onClick={() => handleSelect("world")}
                                    style={{ minWidth: '200px' }} // 幅を指定
                                >
                                    世界モードに切替
                                </NavDropdown.Item>
                            ) : mode === 'world' ? (
                                <NavDropdown.Item
                                    onClick={() => handleSelect("japan")}
                                    style={{ minWidth: '200px' }} // 幅を指定
                                >
                                    日本モードに切替
                                </NavDropdown.Item>
                            ) : ''}
                        </NavDropdown>
                        <NavDropdown
                            id="mode_select"
                            title="学習段階選択"
                            menuVariant="light"
                            align="end"
                        >
                            <NavDropdown.ItemText>
                                編集中
                            </NavDropdown.ItemText>
                            <DropdownDivider />
                        </NavDropdown>
                        <Nav.Link href="https://researchmap.jp/nisylvania/history">更新履歴</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <Modal id="screenshot_modal" show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>スクリーンショット</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {screenshotUrl ? (
                        <img
                            src={screenshotUrl}
                            alt="スクリーンショット"
                            style={{ width: '100%', height: 'auto' }}
                        />
                    ) : (
                        <p>スクリーンショットを取得中...</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        閉じる
                    </Button>
                    {screenshotUrl && (
                        <Button
                            variant="primary"
                            onClick={() => {
                                const link = document.createElement('a');
                                link.download = 'map-screenshot.png';
                                link.href = screenshotUrl;
                                link.click();
                            }}
                        >
                            ダウンロード
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
            <ToastContainer position="top-end" className="p-3">
                <Toast show={showToast} onClose={() => setShowToast(false)} bg="light">
                    <Toast.Header closeButton>
                        <strong className="me-auto">共有</strong>
                    </Toast.Header>
                    <Toast.Body>URLをコピーしました。<br/>URLを共有するだけで設定が再現されます。</Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    );
};

export default Header;